import Layout from "../components/layout/Layout";
import Link from "next/link";
function Custom404() {
  return (
    <>
      <main
        className="main page-404"
        style={{
          height: "100vh",
          width: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div className="text-center ">
          <h1 className="display-2 mb-30">페이지를 찾을 수 없습니다.</h1>
          <p className="font-lg text-grey-700 mb-20">
            정상적인 URL로 접속하여도 계속해서 이 페이지로 이동이된다면 문의를
            남겨주세요.
          </p>
          <Link href="/">
            <a className="btn btn-default submit-auto-width font-xs hover-up mt-30">
              홈으로 이동하기
            </a>
          </Link>
        </div>
      </main>
    </>
  );
}

export default Custom404;
